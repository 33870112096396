import React from 'react';
import Login from "../Login/Login";
import { MuiThemeProvider } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { Action, createBrowserHistory, History, Location } from 'history';
import AuthService from "../../logic/services/AuthService";
import Dashboard from "../Dashboard/Dashboard";
import { GlobalContext } from "../../context/GlobalContext";
import { PageState, View } from "../../model/types";
import ApiService from "../../logic/services/ApiService";
import ICompanyData from "../../model/ICompanyData";
import IAffinityCompanyData from '../../model/IAffinityCompanyData';

const theme = createMuiTheme({
    typography: {
        fontSize: 12,
    },
    palette: {
        text: {
            primary: 'rgba(0, 0, 0, 0.54)'
        }
    }
});

export interface IAppData {
    main: ICompanyData | null,
    affinity: IAffinityCompanyData | null,
}

export interface IAppState {
    data: IAppData,
    domain: string,
    pageState: PageState,
    loadingInfo: string;
    embedded: boolean,
}

export default class App extends React.Component<{}, IAppState> {
    /**
     * Local properties.
     */
    protected history: History;

    /**
     * App constructor
     *
     * @param props - properties
     */
    public constructor(props: Readonly<{}>) {
        super(props);

        // scope binding
        this.historyListener = this.historyListener.bind(this);
        this.contextLoadData = this.contextLoadData.bind(this);
        this.contextChangeView = this.contextChangeView.bind(this);

        // detect embedded state
        const isEmbedded = window.location.search.includes('?embedded');

        // history change listener
        this.history = createBrowserHistory();
        if (!isEmbedded) {
            // do not listen for history change in embedded mode
            this.history.listen(this.historyListener);
        }

        // initial state
        this.state = {
            data: {main: null, affinity: null},
            domain: this.history.location.pathname.substr(1),
            pageState: AuthService.hasIdentity() ? 'dashboard-init' : 'login',
            loadingInfo: '',
            embedded: isEmbedded,
        };
    }

    /**
     * Component was mounted.
     */
    public async componentDidMount(): Promise<void> {
        const domain = this.history.location.pathname.substr(1);
        !domain || await this.contextLoadData(domain, false);
    }

    /**
     * History listener.
     *
     * @param location - new location
     * @param action - navigation action name
     */
    protected async historyListener(location: Location, action: Action): Promise<void> {
        if (action === 'POP') {
            const domain = location.pathname.substr(1);
            if (domain === '') {
                this.setState({pageState: 'dashboard-init', domain: ''});
            } else {
                this.setState({domain});
                await this.contextLoadData(domain, false);
            }
        }
    }

    /**
     * Load domain data (context handler).
     *
     * @param domain - domain
     * @param history - true to update history, otherwise false
     */
    protected async contextLoadData(domain: string, history: boolean = true): Promise<void> {
        const safeDomain = encodeURIComponent(domain);
        this.setState({domain: safeDomain, pageState: 'dashboard-loading'});
        try {
            !history || this.history.push(`/${safeDomain}`);
            if (safeDomain) {
                const data: IAppData = {main: null, affinity: null};
                try {
                    // main data source
                    this.setState({loadingInfo: 'Searching for company in data.notion.vc...'});
                    const response = await ApiService.makeRequest<{ result: ICompanyData }>(
                        'GET',
                        `companies/${safeDomain}`,
                    );
                    data.main = response.data.result;
                } catch (e) {
                    if (e.isAxiosError && e.response.status === 404) {
                        // secondary data source (affinity)
                        this.setState({loadingInfo: 'Searching for company in Affinity...'});
                        const response = await ApiService.makeRequest<{ result: IAffinityCompanyData }>(
                            'GET',
                            `affinity/organizations/search/${safeDomain}`,
                        );
                        data.affinity = response.data.result;
                    } else {
                        throw e;
                    }
                }
                this.setState({data, pageState: 'dashboard-ready'});
            } else {
                this.setState({data: {main: null, affinity: null}, pageState: 'dashboard-init'});
            }
        } catch (e) {
            if (e.isAxiosError && e.message.includes('Request failed with status code 401')) {
                this.contextChangeView('login');
            } else {
                this.setState({pageState: 'dashboard-empty'});
            }
        }
    }

    /**
     * Change app view (context handler).
     *
     * @param view - view
     */
    protected contextChangeView(view: View): void {
        let pageState: PageState = 'login';
        let domain: string = this.state.domain;
        switch (view) {
            case "dashboard":
                pageState = 'dashboard-init';
                break;
            case "login":
                AuthService.clearIdentity();
                this.history.push('/');
                domain = '';
                pageState = 'login';
                break;
        }
        this.setState({domain, pageState})
    }

    /**
     * Render component.
     */
    public render() {
        return (
            <MuiThemeProvider theme={theme}>
                <GlobalContext.Provider value={{
                    appState: this.state,
                    loadData: this.contextLoadData,
                    changeView: this.contextChangeView
                }}>
                    <div className="container-fluid">
                        {this.state.pageState === 'login' ?
                            <Login/>
                            :
                            <Dashboard/>
                        }
                    </div>
                </GlobalContext.Provider>
            </MuiThemeProvider>
        );
    }
}
