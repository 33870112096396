import React from 'react';
import {Button, Paper} from "@material-ui/core";
import {GlobalContext} from "../../context/GlobalContext";
import DomainInput from "../DomainInput/DomainInput";

export default class Header extends React.Component<{}, {}> {
    /**
     * Context binding.
     */
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    /**
     * Header constructor.
     *
     * @param props - properties
     */
    public constructor(props: Readonly<{}>) {
        super(props);

        // scope binding
        this.handleLogout = this.handleLogout.bind(this);
    }

    /**
     * Logout action handler.
     */
    protected handleLogout(): void {
        !this.context.changeView || this.context.changeView('login');
    }

    /**
     * Render component.
     */
    public render() {
        const isEmbedded = this.context.appState?.embedded || false;
        return (
            <Paper className="py-2 bg-primary-dark" square={true}>
                <div className={`container d-flex justify-content-${isEmbedded ? 'end' : 'between'}`}>
                    {!isEmbedded && <DomainInput/>}
                    <div className="ml-4 d-flex">
                        <Button color="primary" variant="contained" onClick={this.handleLogout}>
                            Logout
                        </Button>
                    </div>
                </div>
            </Paper>
        );
    }
}
