import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import ICompanyData from "../../../model/ICompanyData";
import IFundraisingData from "../../../model/IFundraisingData";
import './FundraisingTab.scss';

interface IProps {
    data: ICompanyData;
    active: boolean
}

export default class FundraisingTab extends React.Component<IProps, {}> {
    /**
     * Render component.
     */
    public render() {
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={!this.props.active}
            >
                <h3 className="head-text pt-4 px-4">Fundraising</h3>
                <div className="tab-group pb-0 pt-4">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className="bg-primary-dark">
                                <TableRow>
                                    <TableCell className="bg-primary-dark">Date</TableCell>
                                    <TableCell className="bg-primary-dark cell-long">Investors</TableCell>
                                    <TableCell className="bg-primary-dark">Amount</TableCell>
                                    <TableCell className="bg-primary-dark cell-medium">Round</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.data.fundraising.map((f: IFundraisingData, i) => {
                                    return <TableRow key={`fund-${i}`}>
                                        <TableCell>{f.month}.{f.year}</TableCell>
                                        <TableCell>{f.investors.join(', ')}</TableCell>
                                        <TableCell>{f.amount}{f.currency ? ` (${f.currency})` : ''}</TableCell>
                                        <TableCell>{f.round}</TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Typography>
        );
    }
};
