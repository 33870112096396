import ApiService from "./ApiService";

const IDENTITY_KEY = 'n-webapp-nid';

interface IIdentity {
    email: string;
    first_name: string;
    last_name: string;
    access_token: string;
    refresh_token: string;
    roles: string[];
}

/**
 * Authentication manager.
 */
export default class AuthService {
    /**
     * Perform authentication.
     *
     * @param email - user email
     * @param password - user password
     */
    public static async auth(email: string, password: string): Promise<IIdentity | null> {
        try {
            const res = await ApiService.makeRequest<{ result: IIdentity }>('POST', 'auth/login', {email, password});
            localStorage.setItem(IDENTITY_KEY, JSON.stringify(res.data.result));
            return res.data.result;
        } catch (e) {
            return null;
        }
    }

    /**
     * Perform authentication using refresh token.
     */
    public static async refresh(): Promise<IIdentity | null> {
        try {
            if (this.hasIdentity()) {
                const identity = this.getIdentity();
                const res = await ApiService.makeRequest<{ result: IIdentity }>(
                    'POST',
                    'auth/refresh',
                    {token: identity?.refresh_token || ''},
                );
                localStorage.setItem(IDENTITY_KEY, JSON.stringify(res.data.result));
                return res.data.result;
            }
        } catch (e) {
        }
        return null;
    }

    /**
     * Returns true if user has identity.
     */
    public static hasIdentity(): boolean {
        return localStorage.getItem(IDENTITY_KEY) !== null;
    }

    /**
     * Clear identity.
     */
    public static clearIdentity(): void {
        localStorage.removeItem(IDENTITY_KEY);
    }

    /**
     * Get identity.
     */
    public static getIdentity(): IIdentity | null {
        const identity = localStorage.getItem(IDENTITY_KEY);
        if (identity) {
            return JSON.parse(identity);
        }
        return null;
    }
}
