import React from 'react';
import Header from "../Header/Header";
import CompanyPreview from "../CompanyPreview/CompanyPreview";
import { GlobalContext } from "../../context/GlobalContext";
import { CircularProgress, Paper } from "@material-ui/core";
import AffinityAdd from '../AffinityAdd/AffinityAdd';

export default class Dashboard extends React.Component<{}, {}> {
    /**
     * Render component.
     */
    public render() {
        return (
            <GlobalContext.Consumer>
                {
                    ({appState}) => {
                        if (appState) {
                            return (
                                <div>
                                    <Header/>
                                    <div className="container mt-2">
                                        {appState.pageState === 'dashboard-ready' && <CompanyPreview/>}
                                        {
                                            appState.pageState === 'dashboard-init'
                                            &&
                                            <Paper className="p-5">Please search for a domain...</Paper>
                                        }
                                        {
                                            appState.pageState === 'dashboard-empty'
                                            &&
                                            <Paper className="p-5">
                                                <p><strong>Company data not found</strong></p>
                                                <p>If you just added it to the Affinity CRM please wait a bit and
                                                    refresh the page (Affinity requires some time).
                                                    <AffinityAdd
                                                        leadingDomain={appState.domain}
                                                        name={appState.domain}
                                                    />
                                                </p>
                                            </Paper>
                                        }
                                        {
                                            appState.pageState === 'dashboard-loading'
                                            &&
                                            <div className="d-flex flex-column text-center p-5">
                                                <span className="mb-3"><CircularProgress/></span>
                                                <span>{appState.loadingInfo}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        }
                    }
                }
            </GlobalContext.Consumer>
        );
    }
};
