import React from 'react';
import { Avatar, Card, CardActions, CardHeader, Chip, Paper, Tab, Tabs } from "@material-ui/core";
import { GlobalContext } from "../../context/GlobalContext";
import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StarsIcon from '@material-ui/icons/Stars';
import GeneralTab from "./GeneralTab/GeneralTab";
import FundraisingTab from "./FundraisingTab/FundraisingTab";
import TalentTab from "./TalentTab/TalentTab";
import './CompanyPreview.scss';

interface IState {
    activeTab: number;
}

export default class CompanyPreview extends React.Component<{}, IState> {
    /**
     * Context binding.
     */
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    /**
     * Dashboard constructor.
     *
     * @param props - properties
     */
    public constructor(props: Readonly<{}>) {
        super(props);

        // initial state
        this.state = {activeTab: 0};

        // scope binding
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    /**
     * Tab change handler.
     *
     * @param e - change event
     * @param newValue - new value
     */
    protected handleChangeTab(e: React.ChangeEvent<{}>, newValue: number) {
        this.setState({activeTab: newValue});
    }

    /**
     * Render component.
     */
    public render() {
        const data = this.context.appState?.data;
        if (data && (data.main || data.affinity)) {
            return (
                <div>
                    <Card>
                        <CardHeader
                            avatar={<Avatar className="avatar" src={data.main?.image_url || ''}/>}
                            title={data.main?.name || data.affinity?.name}
                            subheader={data.main?.tagline || ''}
                            titleTypographyProps={{variant: 'h6'}}
                        />
                        <CardActions>
                            {
                                data.main && !data.main.affinity_id &&
                                <Chip label="data.notion.vc" color="primary"/>
                            }
                            {
                                data.main && data.main.affinity_id &&
                                <Chip label="data.notion.vc + Affinity" color="primary"/>
                            }
                            {
                                data.affinity && <Chip label="Affinity" color="secondary"/>
                            }
                        </CardActions>
                    </Card>
                    <Paper className="mt-2">
                        <Tabs className="tabs"
                              value={this.state.activeTab}
                              onChange={this.handleChangeTab}
                              indicatorColor="primary"
                              textColor="primary"
                              centered
                        >
                            <Tab label="General" icon={<InfoIcon/>}/>
                            <Tab label="Fundraising" icon={<MonetizationOnIcon/>} disabled={data.main === null}/>
                            <Tab label="Talent" icon={<StarsIcon/>} disabled={data.main === null}/>
                        </Tabs>
                        <GeneralTab data={data} active={this.state.activeTab === 0}/>
                        {data.main && <FundraisingTab data={data.main} active={this.state.activeTab === 1}/>}
                        {data.main && <TalentTab data={data.main} active={this.state.activeTab === 2}/>}
                    </Paper>
                </div>
            );
        }
    }
};
