import React from 'react';
import {Button, FormControl, Paper, TextField} from "@material-ui/core";
import notionLogo from './../../assets/img/notion.svg';
import AuthService from "../../logic/services/AuthService";
import {Alert} from "@material-ui/lab";
import {GlobalContext} from "../../context/GlobalContext";

type InputFields = 'email' | 'password';
type InputState = {
    [key in InputFields]: string;
}
type State = InputState & { result: boolean | null, loading: boolean };

export default class Login extends React.Component<{}, State> {
    /**
     * Context binding.
     */
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    /**
     * Login constructor.
     *
     * @param props - properties
     */
    public constructor(props: Readonly<{}>) {
        super(props);

        // initial state
        this.state = {
            email: '',
            password: '',
            result: null,
            loading: false,
        };

        // scope binding
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handle input change event.
     *
     * @param field - field name
     * @param value - value
     */
    protected handleInput(field: InputFields, value: string): void {
        this.setState({[field]: value} as State);
    }

    /**
     * Handle form submit.
     *
     * @param e - form event
     */
    protected async handleSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        this.setState({loading: true});
        const result = await AuthService.auth(this.state.email, this.state.password);
        this.setState({
            result: result !== null,
            loading: false,
            password: '',
        }, () => {
            if (this.state.result) {
                !this.context.changeView || this.context.changeView('dashboard');
            }
        })
    }

    /**
     * Render component.
     */
    public render() {
        return (
            <div className="bg-primary-dark vh-100 d-flex justify-content-center align-items-center">
                <Paper className="login-form mx-4 col-sm-8 col-md-6 col-lg-3 p-4 text-center">
                    <form onSubmit={this.handleSubmit}>
                        <img className="mb-4" src={notionLogo} alt="Notion logo"/>
                        {this.state.result === false && <Alert className="mb-4" severity="error">
                            Unable to authenticate your credentials, make sure they are valid.
                        </Alert>}
                        <FormControl className="m-1" fullWidth>
                            <TextField
                                label="Email"
                                type="email"
                                size="small"
                                variant="outlined"
                                value={this.state.email}
                                onChange={(e) => this.handleInput('email', e.target.value)}
                                disabled={this.state.loading}
                                required
                            />
                        </FormControl>
                        <FormControl className="m-1" fullWidth>
                            <TextField
                                label="Password"
                                type="password"
                                size="small"
                                variant="outlined"
                                value={this.state.password}
                                onChange={(e) => this.handleInput('password', e.target.value)}
                                disabled={this.state.loading}
                                required
                            />
                        </FormControl>
                        <FormControl className="mt-3">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={this.state.loading}
                            >
                                Log In
                            </Button>
                        </FormControl>
                    </form>
                </Paper>
            </div>
        );
    }
}
