import axios, {AxiosInstance, AxiosResponse} from 'axios';
import * as envVar from 'env-var';
import AuthService from "./AuthService";

export default class ApiService {
    /**
     * Axios client.
     */
    protected static apiClient: AxiosInstance;

    /**
     * Make HTTP request.
     *
     * @param method - request method
     * @param url - url
     * @param data - data
     */
    public static async makeRequest<T>(method: 'GET' | 'POST', url: string, data?: any): Promise<AxiosResponse<T>> {
        return await this.getClient().request<T>({url, method, data});
    }

    /**
     * Returns API client.
     */
    protected static getClient(): AxiosInstance {
        if (!this.apiClient) {
            this.apiClient = axios.create({
                baseURL: envVar.get("REACT_APP_NOTION_API_ROOT", "https://data.notion.vc/api/").asString(),
                timeout: 60000,
            });
            // refresh token interceptor
            this.apiClient.interceptors.response.use(res => res, async (err) => {
                const originalRequest = err.config;
                if (err.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const identity = await AuthService.refresh();
                    if (identity) {
                        this.apiClient.defaults.headers['Authorization'] = originalRequest.headers['Authorization'] = `Bearer ${identity.access_token}`;
                        return this.apiClient(originalRequest);
                    }
                }
                return Promise.reject(err);
            })
        }
        const identity = AuthService.getIdentity();
        if (identity) {
            this.apiClient.defaults.headers['Authorization'] = `Bearer ${identity.access_token}`;
        } else {
            delete this.apiClient.defaults.headers['Authorization'];
        }
        return this.apiClient;
    }
}
