import React from 'react';
import {TextField} from "@material-ui/core";
import {GlobalContext} from "../../context/GlobalContext";
import './DomainInput.scss';

interface IState {
    value: string;
}

export default class DomainInput extends React.Component<{}, IState> {
    /**
     * Context binding.
     */
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    /**
     * DomainInput constructor.
     *
     * @param props - properties
     */
    public constructor(props: Readonly<{}>) {
        super(props);

        // initial state
        this.state = {
            value: '',
        };

        // scope binding
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Component was mounted.
     */
    public async componentDidMount(): Promise<void> {
        // init input with initial domain
        const value = this.context.appState?.domain || '';
        this.setState({value});
    }

    /**
     * Component was updated.
     */
    public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<IState>): void {
        // update input while navigating across history
        const value = this.context.appState?.domain || '';
        // we don't want to set state if internal state was updated (typing in the input)
        if (prevState.value === this.state.value && this.state.value !== value) {
            this.setState({value});
        }

    }

    /**
     * Domain input action handler.
     */
    protected handleInput(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({value: e.target.value.trim()})
    }

    /**
     * Form submission handler (load domain data).
     */
    protected handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();

        !this.context.loadData || this.context.loadData(this.state.value);
    }

    /**
     * Render component.
     */
    public render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <TextField
                    className="dark"
                    label="Domain name"
                    variant="filled"
                    size="small"
                    value={this.state.value}
                    onChange={this.handleInput}
                />
            </form>
        );
    }
}
