import React from 'react';
import { Typography } from "@material-ui/core";
import { IAppData } from '../../App/App';
import AffinityAdd from "../../AffinityAdd/AffinityAdd";
import moment from 'moment';

interface IProps {
    data: IAppData;
    active: boolean
}

export default class GeneralTab extends React.Component<IProps, {}> {
    /**
     * Render time from now using optional month and year.
     *
     * @param year - year
     * @param month - month
     */
    protected renderTimeFromNow(year: number | null, month: number | null): string {
        let time = 'Missing data';
        if (month && year) {
            time = moment(`${month}-${year}`, "MM-YYYY").fromNow();
        } else if (year) {
            time = moment(year, "YYYY").fromNow();
        }
        return time;
    }

    /**
     * Render component.
     */
    public render() {
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="p-4"
                hidden={!this.props.active}
            >
                <div className="tab-group">
                    <h3 className="head-text">General</h3>
                    <p>{
                        this.props.data.main?.affinity_description ||
                        this.props.data.main?.about ||
                        this.props.data.affinity?.description ||
                        'Missing data'
                    }</p>
                </div>
                <div className="tab-group">
                    <h4 className="sub-head-text">Affinity</h4>
                    {(this.props.data.main?.affinity_id || this.props.data.affinity) && <div className="def-list">
                        <div className="record">
                            <span className="key">Owner</span>
                            <span className="val">{
                                this.props.data.main?.affinity_owners.join(', ') ||
                                this.props.data.affinity?.owners.join(', ') ||
                                'Missing data'
                            }</span>
                        </div>
                        {this.props.data.main && <div className="record">
                            <span className="key">Last contact</span>
                            <span className="val">
                                {
                                    this.props.data.main.affinity_last_email_date &&
                                    moment(this.props.data.main.affinity_last_email_date).fromNow()
                                }
                                {!this.props.data.main.affinity_last_email_date && 'Missing data'}
                            </span>
                        </div>}
                        <div className="record">
                            <span className="key">Introduction source</span>
                            <span className="val">{
                                this.props.data.main?.affinity_source ||
                                this.props.data.affinity?.source ||
                                'Missing data'
                            }</span>
                        </div>
                    </div>
                    }
                    {this.props.data.main?.leading_domain && !this.props.data.main?.affinity_id && !this.props.data.affinity &&
                    <div>
                        Company is not linked with any of Affinity records
                        <AffinityAdd
                            leadingDomain={this.props.data.main.leading_domain}
                            name={this.props.data.main.name}
                        />
                    </div>
                    }
                </div>
                {this.props.data.main && <div className="tab-group">
                    <h4 className="sub-head-text">Key insights</h4>
                    <div className="def-list">
                        <div className="record">
                            <span className="key">Location</span>
                            <span className="val">{this.props.data.main.location}</span>
                        </div>
                        <div className="record">
                            <span className="key">Year founded</span>
                            <span className="val">{this.props.data.main.year_founded}</span>
                        </div>
                        <div className="record">
                            <span className="key">Employees</span>
                            <span className="val">{this.props.data.main.employment_size}</span>
                        </div>
                        <div className="record">
                            <span className="key">Sector</span>
                            <span className="val">{this.props.data.main.industries.join(', ')}</span>
                        </div>
                        <div className="record">
                            <span className="key">Total amount raised</span>
                            <span className="val">€{this.props.data.main.total_amount_raised}m</span>
                        </div>
                        <div className="record">
                            <span className="key">Last raised</span>
                            <span className="val">
                                ~{this.renderTimeFromNow(this.props.data.main.last_funding_year, this.props.data.main.last_funding_month)}
                            </span>
                        </div>
                        <div className="record">
                            <span className="key">Score</span>
                            <span className="val">{this.props.data.main.score}</span>
                        </div>
                    </div>
                </div>}
            </Typography>
        );
    }
};
