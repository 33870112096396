import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import {ISelectData} from "../../model/ISelectData";
import {GlobalContext} from "../../context/GlobalContext";
import {Alert} from "@material-ui/lab";
import ApiService from "../../logic/services/ApiService";

type SelectFields = 'ownerSelected' | 'statusSelected';
type SelectFieldsState = {
    [key in SelectFields]: number | '';
}
type State = {
    open: boolean,
    loading: boolean,
    error: string,
    ownerError: boolean,
    statusError: boolean,
} & SelectFieldsState;

interface IProps {
    leadingDomain: string;
    name: string;
}

export default class AffinityAdd extends React.Component<IProps, State> {
    /**
     * Context binding.
     */
    static contextType = GlobalContext;
    context!: React.ContextType<typeof GlobalContext>;

    /**
     * Local properties.
     */
    protected statuses: ISelectData[] = [];
    protected owners: ISelectData[] = [];
    protected defaultStatus: number | '' = '';
    protected allowedOwners = [
        'Stephen Chandler',
        'Jos White',
        'Chris Tottman',
        'Patrick Norris',
        'Itxaso del Palacio',
        'Susie Meier',
        'Stephanie Opdam',
        'Kamil Mieczakowski',
    ].map(o => o.toLowerCase());

    /**
     * AffinityAdd constructor.
     *
     * @param props - properties
     */
    public constructor(props: Readonly<IProps>) {
        super(props);

        // initial state
        this.state = this.initialState();

        // scope binding
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    /**
     * Component was mounted.
     */
    public async componentDidMount(): Promise<void> {
        const statusesRes = await ApiService.makeRequest<{ result: ISelectData[] }>('GET', 'affinity/ui-statuses');
        this.statuses = statusesRes.data.result;
        const ownersRes = await ApiService.makeRequest<{ result: ISelectData[] }>('GET', 'affinity/ui-owners');
        this.owners = ownersRes.data.result;

        // filter owners
        this.owners = this.owners.filter(o => this.allowedOwners.includes(o.label.toLowerCase()));

        // set default status
        this.defaultStatus = this.statuses.find(o => o.label.toLowerCase().includes('outbound'))?.value || '';
    }

    /**
     * Returns initial state.
     */
    protected initialState(): State {
        return {
            open: false,
            loading: false,
            error: '',
            ownerSelected: '',
            ownerError: false,
            statusSelected: this.defaultStatus,
            statusError: false,
        };
    }

    /**
     * Handle open dialog action.
     */
    protected handleOpenDialog(): void {
        this.setState({...this.initialState(), open: true});
    }

    /**
     * Handle close dialog action.
     */
    protected handleCloseDialog(): void {
        this.setState({open: false})
    }

    /**
     * Handle select change.
     *
     * @param field - select field
     * @param value - value
     */
    protected handleChange(field: SelectFields, value: number): void {
        this.setState({[field]: value} as State);
    }

    /**
     * Handle add to affinity.
     */
    protected async handleAdd(): Promise<void> {
        // validation
        let ownerError = false;
        let statusError = false;
        if (!this.state.ownerSelected) {
            ownerError = true;
        }
        if (!this.state.statusSelected) {
            statusError = true;
        }
        if (ownerError || statusError) {
            this.setState({ownerError, statusError});
        } else {
            // add request
            if (this.props.name && this.props.leadingDomain) {
                try {
                    this.setState({loading: true});
                    await ApiService.makeRequest<{ result: ISelectData[] }>('POST', 'affinity/organizations', {
                        'name': this.props.name,
                        'domain': this.props.leadingDomain,
                        'owner_id': this.state.ownerSelected,
                        'status_id': this.state.statusSelected,
                    });
                    if (this.context.loadData) {
                        this.setState({open: false});
                        await this.context.loadData(this.props.leadingDomain, false)
                    }
                } catch (e) {
                    if (e.isAxiosError && e.response?.data?.status) {
                        this.setState({error: e.response.data.status, loading: false});
                    }
                }
            } else {
                this.setState({error: 'Unexpected error, please refresh page...'});
            }
        }
    }

    /**
     * Render component.
     */
    public render() {
        return (
            <Box component="span">
                <Button
                    color="primary"
                    variant="contained"
                    className="ml-2"
                    onClick={this.handleOpenDialog}
                >
                    Add
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>Add to Affinity</DialogTitle>
                    <DialogContent>
                        {this.state.error && <Alert className="mb-4" severity="error">
                            {this.state.error}
                        </Alert>}
                        <DialogContentText>
                            Please fill the form below to add company to the Affinity CRM.
                        </DialogContentText>
                        <FormControl className="mb-2" fullWidth error={this.state.ownerError}>
                            <InputLabel>Owner</InputLabel>
                            <Select
                                value={this.state.ownerSelected}
                                onChange={(e) => this.handleChange('ownerSelected', e.target.value as number)}
                            >
                                {this.owners.map((d, i) =>
                                    <MenuItem key={`so-${i}`} value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth error={this.state.statusError}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={this.state.statusSelected}
                                onChange={(e) => this.handleChange('statusSelected', e.target.value as number)}
                            >
                                {this.statuses.map((d, i) =>
                                    <MenuItem key={`ss-${i}`} value={d.value}>{d.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions className="p-4">
                        <Button
                            onClick={this.handleCloseDialog}
                            color="primary"
                            disabled={this.state.loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleAdd}
                            color="primary"
                            variant="contained"
                            disabled={this.state.loading}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }
}
