import React from 'react';
import {Card, CardActions, CardContent, CardHeader, IconButton, Link, Typography} from "@material-ui/core";
import ICompanyData from "../../../model/ICompanyData";
import IFounderData from "../../../model/IFounderData";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import {Line} from "react-chartjs-2";
import {ChartData, ChartOptions} from "chart.js";

interface IProps {
    data: ICompanyData;
    active: boolean
}

export default class TalentTab extends React.Component<IProps, {}> {
    /**
     * Render component.
     */
    public render() {
        const labels = [];
        const dataset = [];
        for (const record of this.props.data.employee_growth || []) {
            labels.push(record.date);
            dataset.push(record.value);
        }
        const chartData: ChartData = {
            labels,
            datasets: [{
                data: dataset
            }]
        };
        const chartOptions: ChartOptions = {
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
        };
        return (
            <Typography
                component="div"
                role="tabpanel"
                className="p-4"
                hidden={!this.props.active}
            >
                <h3 className="head-text">Talent</h3>
                <div className="tab-group">
                    <h4 className="sub-head-text">Founders</h4>
                    {this.props.data.founders.length === 0 && 'Missing data'}
                    <div className="row">
                        {this.props.data.founders.map((f: IFounderData, i) => {
                            return <div key={`found-${i}`} className="col-sm-12 col-lg-6 mb-3">
                                <Card className="col-12 h-100">
                                    <CardHeader title={f.name}/>
                                    <CardContent>
                                        <div className="def-list">
                                            <div className="record">
                                                <span className="key">Past experiences</span>
                                                <span
                                                    className="val">{f.experiences.join(', ') || 'Missing data'}</span>
                                            </div>
                                            <div className="record">
                                                <span className="key">Education</span>
                                                <span
                                                    className="val">{f.educations.join(', ') || 'Missing data'}</span>
                                            </div>
                                        </div>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        {f.linked_in_url &&
                                        <Link href={f.linked_in_url} target="_blank" rel="noreferrer">
                                            <IconButton>
                                                <LinkedInIcon/>
                                            </IconButton>
                                        </Link>}
                                    </CardActions>
                                </Card>
                            </div>
                        })}
                    </div>
                </div>
                <div className="tab-group">
                    <h4 className="sub-head-text">Employee growth</h4>
                    <div className="chart">
                        {this.props.data.employee_growth.length > 0 && <Line data={chartData} options={chartOptions}/>}
                        {this.props.data.employee_growth.length === 0 && 'Missing data'}
                    </div>
                </div>
            </Typography>
        );
    }
};
