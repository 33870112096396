import * as React from 'react';
import {View} from "../model/types";
import {IAppState} from "../components/App/App";

export interface GlobalContextProps {
    appState: IAppState;
    loadData: (domain: string, history?: boolean) => Promise<void>;
    changeView: (view: View) => void;
}

export const GlobalContext = React.createContext<Partial<GlobalContextProps>>({});
